import revive_payload_client_HQftdVUJQq from "F:/33/diary.ilovearomagrace.com/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_gpptbavbig4sfrg5uipucn5t4y/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_LQDUoD3E46 from "F:/33/diary.ilovearomagrace.com/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_gpptbavbig4sfrg5uipucn5t4y/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_KN8xBCKB8K from "F:/33/diary.ilovearomagrace.com/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_gpptbavbig4sfrg5uipucn5t4y/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_4RD1Y92sOF from "F:/33/diary.ilovearomagrace.com/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_gpptbavbig4sfrg5uipucn5t4y/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_v8iTCVLumq from "F:/33/diary.ilovearomagrace.com/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_gpptbavbig4sfrg5uipucn5t4y/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_93ocNMAwvV from "F:/33/diary.ilovearomagrace.com/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_gpptbavbig4sfrg5uipucn5t4y/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_vnObWBLBPw from "F:/33/diary.ilovearomagrace.com/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_gpptbavbig4sfrg5uipucn5t4y/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_aRnxoEfZpC from "F:/33/diary.ilovearomagrace.com/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.2_vue@3.5.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "F:/33/diary.ilovearomagrace.com/.nuxt/components.plugin.mjs";
import prefetch_client_t5OCaPFL4M from "F:/33/diary.ilovearomagrace.com/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_gpptbavbig4sfrg5uipucn5t4y/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import element_plus_teleports_plugin_h4Dmekbj62 from "F:/33/diary.ilovearomagrace.com/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "F:/33/diary.ilovearomagrace.com/.nuxt/element-plus-injection.plugin.mjs";
import plugin_bb3FJ1NUVA from "F:/33/diary.ilovearomagrace.com/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@4.21.2/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_BgKvU4jz5Q from "F:/33/diary.ilovearomagrace.com/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.2_vue@3._i4hu7m5bkmh2sk5cfg32vn5z6q/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import element_plus_HUzmwi0JYR from "F:/33/diary.ilovearomagrace.com/plugins/element-plus.ts";
export default [
  revive_payload_client_HQftdVUJQq,
  unhead_LQDUoD3E46,
  router_KN8xBCKB8K,
  payload_client_4RD1Y92sOF,
  navigation_repaint_client_v8iTCVLumq,
  check_outdated_build_client_93ocNMAwvV,
  chunk_reload_client_vnObWBLBPw,
  plugin_vue3_aRnxoEfZpC,
  components_plugin_KR1HBZs4kY,
  prefetch_client_t5OCaPFL4M,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  plugin_bb3FJ1NUVA,
  plugin_BgKvU4jz5Q,
  element_plus_HUzmwi0JYR
]