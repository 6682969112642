import { default as calendarvG64OrcLslMeta } from "F:/33/diary.ilovearomagrace.com/pages/calendar.vue?macro=true";
import { default as callbackzBXjtjvJcXMeta } from "F:/33/diary.ilovearomagrace.com/pages/callback.vue?macro=true";
import { default as diariesDZCUVs2bByMeta } from "F:/33/diary.ilovearomagrace.com/pages/diaries.vue?macro=true";
import { default as _91id_93Dys6b2eYkBMeta } from "F:/33/diary.ilovearomagrace.com/pages/diary/[id].vue?macro=true";
import { default as error94YSKX3IFQMeta } from "F:/33/diary.ilovearomagrace.com/pages/error.vue?macro=true";
import { default as loginPgEUtbaFIUMeta } from "F:/33/diary.ilovearomagrace.com/pages/login.vue?macro=true";
import { default as logout61S4PM3OOnMeta } from "F:/33/diary.ilovearomagrace.com/pages/logout.vue?macro=true";
import { default as tATF69lTAD1Meta } from "F:/33/diary.ilovearomagrace.com/pages/t.vue?macro=true";
import { default as t1cNCqNcGU22Meta } from "F:/33/diary.ilovearomagrace.com/pages/t1.vue?macro=true";
import { default as termsCAawOrnhyjMeta } from "F:/33/diary.ilovearomagrace.com/pages/terms.vue?macro=true";
import { default as user_base_oilspHzS93CLUwMeta } from "F:/33/diary.ilovearomagrace.com/pages/user_base_oils.vue?macro=true";
import { default as user_oils5isH9EmdJvMeta } from "F:/33/diary.ilovearomagrace.com/pages/user_oils.vue?macro=true";
import { default as user_toolsAiKEhVjhAVMeta } from "F:/33/diary.ilovearomagrace.com/pages/user_tools.vue?macro=true";
import { default as waiting_approvedKheC2W7f6Meta } from "F:/33/diary.ilovearomagrace.com/pages/waiting_approve.vue?macro=true";
export default [
  {
    name: "calendar",
    path: "/calendar",
    component: () => import("F:/33/diary.ilovearomagrace.com/pages/calendar.vue").then(m => m.default || m)
  },
  {
    name: "callback",
    path: "/callback",
    component: () => import("F:/33/diary.ilovearomagrace.com/pages/callback.vue").then(m => m.default || m)
  },
  {
    name: "diaries",
    path: "/diaries",
    component: () => import("F:/33/diary.ilovearomagrace.com/pages/diaries.vue").then(m => m.default || m)
  },
  {
    name: "diary-id",
    path: "/diary/:id()",
    component: () => import("F:/33/diary.ilovearomagrace.com/pages/diary/[id].vue").then(m => m.default || m)
  },
  {
    name: "error",
    path: "/error",
    component: () => import("F:/33/diary.ilovearomagrace.com/pages/error.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginPgEUtbaFIUMeta || {},
    component: () => import("F:/33/diary.ilovearomagrace.com/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("F:/33/diary.ilovearomagrace.com/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: "t",
    path: "/t",
    component: () => import("F:/33/diary.ilovearomagrace.com/pages/t.vue").then(m => m.default || m)
  },
  {
    name: "t1",
    path: "/t1",
    component: () => import("F:/33/diary.ilovearomagrace.com/pages/t1.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("F:/33/diary.ilovearomagrace.com/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "user_base_oils",
    path: "/user_base_oils",
    component: () => import("F:/33/diary.ilovearomagrace.com/pages/user_base_oils.vue").then(m => m.default || m)
  },
  {
    name: "user_oils",
    path: "/user_oils",
    component: () => import("F:/33/diary.ilovearomagrace.com/pages/user_oils.vue").then(m => m.default || m)
  },
  {
    name: "user_tools",
    path: "/user_tools",
    component: () => import("F:/33/diary.ilovearomagrace.com/pages/user_tools.vue").then(m => m.default || m)
  },
  {
    name: "waiting_approve",
    path: "/waiting_approve",
    meta: waiting_approvedKheC2W7f6Meta || {},
    component: () => import("F:/33/diary.ilovearomagrace.com/pages/waiting_approve.vue").then(m => m.default || m)
  }
]