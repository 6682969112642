// utils/logger.ts

type LogLevel = 'debug' | 'info' | 'warn' | 'error';

export async function logMessage(level: LogLevel, message: string, source: string): Promise<void> {
  try {
    // 記錄日誌到服務器
    await $fetch('/api/logs', {
      method: 'POST',
      body: {
        level,
        message,
        source,
      },
    });

    // 根據日誌級別輸出到控制台
    switch (level) {
      case 'debug':
        console.debug(`[${source}] ${message}`);
        break;
      case 'info':
        console.info(`[${source}] ${message}`);
        break;
      case 'warn':
        console.warn(`[${source}] ${message}`);
        break;
      case 'error':
        console.error(`[${source}] ${message}`);
        break;
    }
  } catch (error) {
    console.error('Failed to log message:', error);
  }
}
