import { useUserStore } from '~/stores/user';
import { logMessage } from '~/utils/logger'; // 引入共用的日志函数

export default defineNuxtRouteMiddleware(async (to, from) => {
  const userStore = useUserStore();

  // 记录进入中间件的日志
  await logMessage('info', `Navigating to ${to.path} from ${from.path}`, 'middleware/auth.global.ts');

  // 排除登录、回调和登出页面路径
  if (to.path === '/login' || to.path === '/callback' || to.path === '/error' || to.path === '/logout') {
    await logMessage('info', 'Excluding authentication check for special paths.', 'middleware/auth.global.ts');
    return;
  }

  // 尝试加载用户信息（如果在应用启动时未加载）
  if (!userStore.userInfo) {
    await logMessage('info', 'User info not found in store, attempting to fetch.', 'middleware/auth.global.ts');
    await userStore.fetchUser(); // 假设 `fetchUser` 方法会加载或恢复用户信息
  }

  // 如果用户已经有 id，说明已经登录，直接返回
  if (userStore.userInfo?.id != null) {
    await logMessage('info', `User ID ${userStore.userInfo.id} is already logged in.`, 'middleware/auth.global.ts');
    return;
  }

  // 如果仍然没有用户信息，则记录日志并重定向到登录页面
  await logMessage('warn', 'User not logged in, redirecting to login page.', 'middleware/auth.global.ts');
  return navigateTo(`/login?redirect=${encodeURIComponent(to.fullPath)}`, { redirectCode: 301 });
});
