import CryptoJS from 'crypto-js';

const secretKey = '4d2b6a35c8e819e724b4b549e5314d3a3fd4e7e1b75e1f9e7a45!@#x中ky'; // 这是你的加密密钥

// 定义泛型类型 T，使得 encryptData 可以接受任意类型的输入
export function encryptData<T>(data: T): string {
  // 将数据转换为字符串后加密
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
}

// 将解密后的数据解析为对象，返回类型为 T
export function decryptData<T>(ciphertext: string): T {
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  // 将解密后的字符串解析为对象
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) as T;
}
