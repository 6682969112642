import { defineStore } from 'pinia';
import { encryptData, decryptData } from '~/utils/crypto';

interface User {
  id: string;
  name: string;
  avatar: string;
  is_valid: boolean;
  is_admin: boolean;
  user_level: number;
  expire_date: string;
}

export const useUserStore = defineStore('user', {
  state: () => ({
    userInfo: null as User | null,
  }),
  actions: {
    setUser(user: User) {
      this.userInfo = user;
      if (process.client) {
        this.saveUserToStorage();
      }
    },
    clearUser() {
      this.userInfo = null;
      if (process.client) {
        localStorage.removeItem('userStore');
      }
    },
    async fetchUser(userId?: string) {
      try {
        const id = userId || (process.client ? this.getUserIdFromStorage() : undefined);
        if (id) {
          const response = await $fetch<{ user: User }>(`/api/users/${id}`);
          this.setUser(response.user);
        }
      } catch (error) {
        console.error('Failed to fetch user:', error);
        this.clearUser();
      }
    },
    getUserIdFromStorage(): string | undefined {
      if (!process.client) return undefined;

      const storedData = localStorage.getItem('userStore');
      if (!storedData) return undefined;

      try {
        const decryptedData = decryptData<{ userInfo: User; expireAt: string }>(storedData);
        if (new Date() > new Date(decryptedData.expireAt)) {
          this.clearUser();
          return undefined;
        }

        this.userInfo = decryptedData.userInfo;
        return decryptedData.userInfo.id;
      } catch (error) {
        console.error('Failed to decrypt user data:', error);
        this.clearUser();
        return undefined;
      }
    },
    saveUserToStorage() {
      if (!process.client || !this.userInfo) return;

      const expireAt = new Date();
      expireAt.setDate(expireAt.getDate() + 30);

      const dataToStore = {
        userInfo: this.userInfo,
        expireAt: expireAt.toISOString(),
      };

      const encryptedData = encryptData(dataToStore);
      localStorage.setItem('userStore', encryptedData);
    },
    resetExpiration() {
      if (this.userInfo && process.client) {
        this.saveUserToStorage();
      }
    },
  },
  persist: false,
});
